<template>
  <div class="app">
    <header class="header">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
        <span>黒鬼选号网站</span>
      </div>
      <nav class="menu">
        <button v-for="(item, index) in menuItems" :key="item" @click="selectLottery(index)">
          {{ item }}
        </button>
      </nav>
    </header>
    <main class="main-content">
      <div class="lottery-selector" v-for="(lot, index) in lotteries" :key="index" v-show="currentLottery === index">
        <h2>{{ lot.name }}</h2>
        <div class="controls">
          <button @click="generateLottery(index)">生成号码</button>
        </div>
        <div class="results">
          <!-- Lottery 1 Results -->
          <div v-if="index === 0 && lot.mainNumbers.length">
            <h3>前区号码 ：</h3>
            <p>{{ lot.mainNumbers.join(', ') }}</p>
            <h3>后区号码 ：</h3>
            <p>{{ lot.bonusNumbers.join(', ') }}</p>
          </div>
          <!-- Lottery 2 Results -->
          <div v-if="index === 1 && lot.newLotteryNumbers.length">
            <h3>号码 ：</h3>
            <p>{{ lot.newLotteryNumbers.join(', ') }}</p>
          </div>
          <!-- Lottery 3 Results -->
          <div v-if="index === 2 && lot.newLotteryNumbers.length">
            <h3>号码 ：</h3>
            <p>{{ lot.newLotteryNumbers.join(', ') }}</p>
          </div>
          <!-- Lottery 4 Results -->
          <div v-if="index === 3 && lot.mainNumbers.length">
            <h3>号码 ：</h3>
            <p>{{ lot.mainNumbers.join(', ') }}</p>
            <h3>号码 ：</h3>
            <p>{{ lot.bonusNumbers.join(', ') }}</p>
          </div>
          <!-- Lottery 5 Results -->
          <div v-if="index === 4 && lot.mainNumbers.length">
            <h3>前区号码 ：</h3>
            <p>{{ lot.mainNumbers.join(', ') }}</p>
            <h3>后区号码 ：</h3>
            <p>{{ lot.bonusNumbers.join(', ') }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: ['大乐透', '快乐8', '排列三', '组选大乐透', '双色球'],
      currentLottery: null,
      lotteries: [
        {
          name: '大乐透',
          mainNumbers: [],
          bonusNumbers: []
        },
        {
          name: '快乐8',
          newLotteryNumbers: []
        },
        {
          name: '排列三',
          newLotteryNumbers: []
        },
        {
          name: '组选大乐透',
          mainNumbers: [],
          bonusNumbers: []
        },
        {
          name: '双色球',
          mainNumbers: [],
          bonusNumbers: []
        }
      ]
    };
  },
  methods: {
    generateLottery(index) {
      const lottery = this.lotteries[index];
      if (index === 0) { // 1-35选5个，1-12选2个
        lottery.mainNumbers = this.generateRandomNumbers(5, 35);
        lottery.bonusNumbers = this.generateRandomNumbers(2, 12);
      } else if (index === 1) { // 1-80选5个
        lottery.newLotteryNumbers = this.generateRandomNumbers(5, 80);
      } else if (index === 2) { // 000-999选3个
        lottery.newLotteryNumbers = this.generateRandomNumbers(3, 1000);
      } else if (index === 3) { // 1-35选6个，1-12选3个
        lottery.mainNumbers = this.generateRandomNumbers(6, 35);
        lottery.bonusNumbers = this.generateRandomNumbers(3, 12);
      } else if (index === 4) { // 1-33选6个，1-15选1个
        lottery.mainNumbers = this.generateRandomNumbers(6, 33);
        lottery.bonusNumbers = this.generateRandomNumbers(1, 15);
      }
    },
    generateRandomNumbers(count, max) {
      const numbers = new Set();
      while (numbers.size < count) {
        numbers.add(Math.floor(Math.random() * max) + 1);
      }
      return Array.from(numbers).sort((a, b) => a - b);
    },
    selectLottery(index) {
      this.currentLottery = index;
    }
  }
};
</script>

<style scoped>
.app {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.header {
  background-color: #007bff;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.logo img {
  position:flex;
  height: 40px;
  margin-right: 10px;
}

.logo span {
  font-weight: bold;
  font-size: 1.5rem;
  vertical-align: middle;
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.menu button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.menu button:hover {
  background-color: #0057b3;
}

.main-content {
  padding: 2rem;
  text-align: center;
}

.lottery-selector {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lottery-selector h2 {
  margin-top: 0;
  color: #333;
}

.controls button {
  margin: 10px 5px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.controls button:hover {
  background-color: #218838;
}

.results {
  margin-top: 2rem;
}

.results h3 {
  margin-bottom: 0.5rem;
  color: #007bff;
}

.results p {
  margin-top: 0.5rem;
  font-weight: bold;
  color: #333;
}
</style>